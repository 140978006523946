import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { BlogMetaNodeType } from "@graphQL/blogMetaContent";
import Container from "@components/Container";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { Flex, FlexItem } from "@ryerson/frontend.layout";
import Moment from "react-moment";
import ButtonLink from "@components/ButtonLink";
import Dots from "@components/MoreDots";
import { Link } from "@ryerson/frontend.navigation";
import { Icon } from "@ryerson/frontend.assets";

const Divider = styled.div`
	display: block;
	width: 100%;
	height: 1px;
	box-sizing: border-box;
	opacity: 0.2;
`;

const DotsContainer = styled.div`
	display: block;
	width: 100%;
	height: auto;
	box-sizing: border-box;
	position: relative;
`;

const Overlay = styled(Flex)`
	position: absolute;
	top: 0px;
	left: 0px;
	height: 100%;
	box-sizing: border-box;
	padding: 40px;
	width: 100%;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 31.9%, rgba(0, 0, 0, 0.8) 100%);
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 20px;
`;

const Pagination = styled.div`
	display: block;
	width: 100%;
	text-align: center;
	margin-bottom: 80px;
`;

const PaginationButton = styled.div`
	display: inline-block;
	width: 65px;
	cursor: pointer;
	user-select: none;
	height: 24px;
	vertical-align: top;
`;

const PaginationNumbers = styled.div`
	display: inline-block;
	width: auto;
	white-space: nowrap;
	max-width: 240px;
	padding-left: 30px;
	padding-right: 30px;
	box-sizing: border-box;
	height: 24px;
	vertical-align: top;
`;

const BlogList: React.FC<{
	blogMetaContent: BlogMetaNodeType[];
	itemsPerPage: number;
	itemsPerRow: number;
	title: string;
}> = ({ blogMetaContent, itemsPerPage, itemsPerRow, title }) => {
	//let firstBlog = blogMetaContent.find(
	//	(blog: BlogMetaNodeType) => blog.url === "your-partner-for-advanced-fabrication"
	//);
	//if (!firstBlog) {
	//	firstBlog = blogMetaContent[0];
	//}
	const nBlogs = blogMetaContent.length - 1;
	const nPages = Math.ceil(nBlogs / itemsPerPage);
	const { theme } = useTheme();
	const [slicePoint, setSlicePoint] = React.useState([1, itemsPerPage + 1]);
	const [currentPage, setCurrentPage] = React.useState(1);
	const [pageNumbersShowing, setPageNumbersShowing] = React.useState([1, 2, 3, 4, "...", nPages]);
	React.useEffect(() => {
		let p1 = (currentPage - 1) * itemsPerPage + 1;
		let p2 = (currentPage - 1) * itemsPerPage + itemsPerPage + 1;
		setSlicePoint([p1, p2]);
		if (nPages > 6) {
			if (currentPage === 1) {
				setPageNumbersShowing([1, 2, 3, 4, "...", nPages]);
			} else if (currentPage === nPages) {
				setPageNumbersShowing([1, "...", nPages - 3, nPages - 2, nPages - 1, nPages]);
			} else if (currentPage === nPages - 1) {
				setPageNumbersShowing([1, "...", nPages - 3, nPages - 2, nPages - 1, nPages]);
			} else if (currentPage === nPages - 2) {
				setPageNumbersShowing([1, "...", nPages - 3, nPages - 2, nPages - 1, nPages]);
			} else if (currentPage === 2) {
				setPageNumbersShowing([1, 2, 3, 4, "...", nPages]);
			} else if (currentPage === 3) {
				setPageNumbersShowing([1, 2, 3, 4, "...", nPages]);
			} else {
				setPageNumbersShowing([
					1,
					"...",
					currentPage - 1,
					currentPage,
					currentPage + 1,
					"...",
					nPages,
				]);
			}
		} else {
			let pageNumbers = [];
			for (let i = 1; i <= nPages; i++) {
				pageNumbers.push(i);
			}
			setPageNumbersShowing(pageNumbers);
		}
	}, [currentPage]);
	return (
		<>
			<DotsContainer>
				<Dots
					type="light"
					css={css`
						height: 552px;
						@media (max-width: ${theme.breakpoints.lg}) {
							height: 382px;
						}
					`}
				/>
				<Dots
					type="dark"
					css={css`
						height: 48px;
					`}
				/>
				<Dots
					type="light"
					css={css`
						height: 60px;
					`}
				/>
				<Container
					css={css`
						position: absolute;
						left: 50%;
						top: 80px;
						transform: translateX(-50%);
						height: 500px;
						@media (max-width: ${theme.breakpoints.lg}) {
							height: 400px;
							top: 40px;
						}
					`}
				>
					<div
						css={css`
							width: 100%;
							height: 500px;
							position: relative;
							background-image: url(${blogMetaContent[0].heroImage.file.url});
							background-size: cover;
							background-position: center center;
							@media (max-width: ${theme.breakpoints.lg}) {
								height: 400px;
							}
						`}
					>
						<Overlay
							justifyContent="flex-end"
							direction="column"
							css={css`
								@media (max-width: ${theme.breakpoints.lg}) {
									padding: 20px;
								}
							`}
						>
							<FlexItem>
								<Typography
									variant="div"
									color={theme.colors.primary.white}
									size="md"
									weight="normal"
									css={css`
										margin-bottom: 15px;
										opacity: 0.8;
										@media (max-width: ${theme.breakpoints.lg}) {
											font-size: 16px;
											line-height: 28px;
										}
									`}
								>
									<Moment format="D MMMM YYYY">{blogMetaContent[0].date}</Moment>
									<Typography
										variant="span"
										weight="normal"
										color={theme.colors.primary.lighterGray}
										css={css`
											height: 30px;
											border: 1px solid ${theme.colors.primary.white};
											padding: 0px 10px;
											box-sizing: border-box;
											border-radius: 20px;
											display: inline-block;
											line-height: 28px;
											margin-left: 20px;
										`}
									>
										{blogMetaContent[0].category}
									</Typography>
								</Typography>
								<Typography
									variant="div"
									css={css`
										font-size: 32px;
										line-height: 39px;
										max-width: 650px;
										margin-bottom: 30px;
										@media (max-width: ${theme.breakpoints.lg}) {
											font-size: 32px;
											line-height: 39px;
											margin-bottom: 20px;
										}
									`}
									weight="bold"
									color={theme.colors.primary.white}
								>
									{blogMetaContent[0].title}
								</Typography>
								<ButtonLink
									external={false}
									to={"/insights/" + blogMetaContent[0].url}
								>
									Read More
								</ButtonLink>
							</FlexItem>
						</Overlay>
					</div>
				</Container>
			</DotsContainer>
			<Container>
				<Divider
					css={css`
						border-top: 1px solid ${theme.colors.tertiary.background};
						margin-bottom: 60px;
						@media (max-width: ${theme.breakpoints.lg}) {
							margin-bottom: 40px;
						}
					`}
				/>
				<Typography
					variant="div"
					type="primary"
					weight="bold"
					color={theme.colors.primary.secondaryBrand}
					css={css`
						margin-bottom: 40px;
						text-align: center;
						font-size: 32px;
						@media (max-width: ${theme.breakpoints.lg}) {
							font-size: 32px;
							margin-bottom: 20px;
						}
					`}
				>
					{title}
				</Typography>
				<Flex
					direction="row"
					justifyContent="flex-start"
					css={css`
						height: auto;
						gap: 30px;
						padding-bottom: 60px;
						flex-wrap: wrap;
						@media (max-width: ${theme.breakpoints.lg}) {
							gap: 20px;
							padding-bottom: 40px;
						}
					`}
				>
					{blogMetaContent
						.slice(slicePoint[0], slicePoint[1])
						.map((item: BlogMetaNodeType, index) => {
							return (
								<FlexItem
									key={index}
									css={css`
										width: calc(
											${100 / itemsPerRow}% -
												${((itemsPerRow - 1) * 30) / itemsPerRow}px
										);
										@media (max-width: ${theme.breakpoints.lg}) {
											width: calc(
												${100 / itemsPerRow}% -
													${((itemsPerRow - 1) * 20) / itemsPerRow}px
											);
										}
									`}
								>
									<img
										src={item.heroImage.file.url}
										css={css`
											width: 100%;
											height: 250px;
											object-fit: cover;
											display: block;
											margin-bottom: 20px;
											@media (max-width: ${theme.breakpoints.lg}) {
												margin-bottom: 10px;
												height: 150px;
											}
										`}
									/>
									<Typography
										variant="div"
										size="sm"
										type="primary"
										css={css`
											margin-bottom: 10px;
										`}
									>
										<Moment format="D MMMM YYYY">{item.date}</Moment>
									</Typography>
									<Typography
										variant="div"
										css={css`
											font-size: 20px;
											margin-bottom: 10px;
											@media (max-width: ${theme.breakpoints.lg}) {
												font-size: 18px;
											}
										`}
										weight="bolder"
										color={theme.colors.primary.secondaryBrand}
									>
										{item.title}
									</Typography>
									<Typography
										variant="span"
										weight="normal"
										color={theme.colors.primary.secondaryBrand}
										type="primary"
										css={css`
											height: 26px;
											border: 1px solid ${theme.colors.primary.secondaryBrand};
											padding: 0px 10px;
											box-sizing: border-box;
											border-radius: 20px;
											display: inline-block;
											line-height: 24px;
											@media (max-width: ${theme.breakpoints.lg}) {
												line-height: 24px;
												font-size: 16px;
											}
										`}
									>
										{item.category}
									</Typography>
									<Link to={"/insights/" + item.url}>
										<Typography
											variant="div"
											size="md"
											color={theme.colors.primary.primaryBrand}
											css={css`
												margin-top: 10px;
												@media (max-width: ${theme.breakpoints.lg}) {
													font-size: 16px;
												}
											`}
										>
											Read More{" "}
											<Icon
												size="sm"
												color={theme.colors.primary.primaryBrand}
												icon="backward-arrow"
												css={css`
													transform: rotate(180deg);
													display: inline-block;
													padding-bottom: 2px;
													cursor: pointer;
												`}
											/>
										</Typography>
									</Link>
								</FlexItem>
							);
						})}
				</Flex>
				<Spacer />
				{nPages > 1 && (
					<Pagination
						css={css`
							@media (max-width: ${theme.breakpoints.lg}) {
								margin-bottom: 50px;
							}
						`}
					>
						<PaginationButton
							css={css`
								@media (max-width: ${theme.breakpoints.lg}) {
									width: 45px;
								}
							`}
							onClick={() => {
								if (currentPage > 1) {
									setCurrentPage(currentPage - 1);
								}
							}}
						>
							<Typography
								variant="div"
								size="md"
								color={
									currentPage === 1
										? theme.colors.primary.secondaryBrand
										: theme.colors.primary.primaryBrand
								}
							>
								<Icon
									size="xs"
									icon="chevron-left"
									css={css`
										display: inline-block;
										vertical-align: top;
										padding-top: 0.5px;
										padding-right: 5px;
									`}
									color={
										currentPage === 1
											? theme.colors.primary.secondaryBrand
											: theme.colors.primary.primaryBrand
									}
								/>{" "}
								Prev
							</Typography>
						</PaginationButton>
						<PaginationNumbers
							css={css`
								@media (max-width: ${theme.breakpoints.lg}) {
									padding-left: 15px;
									padding-right: 15px;
									box-sizing: border-box;
									max-width: calc(100% - 100px);
								}
							`}
						>
							<Flex
								direction="row"
								justifyContent="space-between"
								css={css`
									gap: 6px;
									@media (max-width: ${theme.breakpoints.lg}) {
										justify-content: flex-start;
									}
								`}
							>
								{pageNumbersShowing.map((item: string | number, index: number) => {
									return (
										<FlexItem
											key={index}
											css={css`
												${item === "..." && "cursor:default;"};
											`}
											onClick={() => {
												if (item !== "..." && typeof item === "number")
													setCurrentPage(item);
											}}
										>
											<Typography
												variant="div"
												size="md"
												color={theme.colors.primary.secondaryBrand}
												css={css`
												width:24px;
												height:24px;
												line-height:24px;
												user-select:none;
												border-radius:24px;
												text-align:center;
												display:inline-block;
												box-sizing:border-box;
												vertical-align:top;
												${item === currentPage && "background-color:" + theme.colors.primary.accentBrand + ";"}}
											`}
											>
												{item}
											</Typography>
										</FlexItem>
									);
								})}
							</Flex>
						</PaginationNumbers>
						<PaginationButton
							onClick={() => {
								if (currentPage < nPages) {
									setCurrentPage(currentPage + 1);
								}
							}}
							css={css`
								@media (max-width: ${theme.breakpoints.lg}) {
									width: 45px;
								}
							`}
						>
							<Typography
								variant="div"
								size="md"
								color={
									currentPage === nPages
										? theme.colors.primary.secondaryBrand
										: theme.colors.primary.primaryBrand
								}
							>
								Next{" "}
								<Icon
									size="xs"
									icon="chevron-right"
									css={css`
										display: inline-block;
										vertical-align: top;
										padding-top: 1px;
										padding-left: 5px;
									`}
									color={
										currentPage === nPages
											? theme.colors.primary.secondaryBrand
											: theme.colors.primary.primaryBrand
									}
								/>
							</Typography>
						</PaginationButton>
					</Pagination>
				)}
			</Container>
		</>
	);
};

export default BlogList;
